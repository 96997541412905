<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <div class="col-12 md:col-12">
      <app-basic-input
        label="Name"
        [placeholder]="'Enter Name'"
        [control]="formGroup?.controls?.name"
        [viewMode]="fieldViewMode"
      ></app-basic-input>
    </div>
    <div class="col-12 md:col-6">
      <app-icon-selector [control]="formGroup?.controls?.icon" [viewMode]="fieldViewMode"> </app-icon-selector>
    </div>

    <div class="col-12 md:col-6">
      <app-color-input label="Color" name="Color" [control]="formGroup?.controls?.color" [viewMode]="fieldViewMode">
      </app-color-input>
    </div>
    <div class="col-12 md:col-6">
      <app-target-code-selector
        [label]="'Report Configuration'"
        [placeholder]="'Search Records'"
        [control]="formGroup?.controls?.reportTemplate"
        [viewMode]="fieldViewMode"
        [multi]="false"
        [optionLabel]="'label'"
        [optionValue]="'code'"
        [targetTypes]="['REPORT_CONFIGURATION_TEMPLATE']"
      >
      </app-target-code-selector>
    </div>
    <div class="col-12 md:col-12">
      <app-text-editor
        [name]="'description'"
        label="Description"
        [placeholder]="'Enter Text...'"
        [control]="formGroup?.controls?.description"
        [viewMode]="fieldViewMode"
        [advanced]="true"
      ></app-text-editor>
    </div>
  </div>
  <div class="flex flex-row-reverse justify-content-between align-items-center mt-2">
    <div class="flex flex-row-reverse gap-2">
      @if (showSaveButton) {
        <app-button [action]="submitButtonAction"></app-button>
      }
      @if (showSaveButton) {
        <app-button [action]="submitSaveButtonAction"></app-button>
      }
      @if (showInPlaceButton) {
        <app-button [action]="submitInPlaceButtonAction"></app-button>
      }
      @if (fieldViewMode == 'create') {
        <app-button [action]="cancelButtonAction"></app-button>
      }
    </div>
    @if (fieldViewMode == 'create') {
      <app-switch-input
        [label]="'general.messages.create another record' | translate"
        [name]="'inPlaceCreate'"
        [labelWidthFit]="true"
        [control]="inPlaceCreateControl"
        [viewMode]="'create'"
        [styleClass]="'mb-0'"
      >
      </app-switch-input>
    }
  </div>
</form>
